.cont {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 449px) {
  .map {
    right: -200px;
  }

  .links svg {
    margin: 0 8px;
  }

  .drop-pin {
    z-index: 2;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 360px;
    right: 160px;
  }

  .brooklin, .fort, .manhattan {
    display: none !important;
  }

  .location-box {
    justify-content: center;
    display: flex;
    top: 300px;
    right: -70px;
  }

  .location-box img {
    display: none;
  }

  .location-box picture {
    justify-content: center;
    display: flex;
  }

  .location-content {
    display: none;
  }

  .location-content-small {
    align-self: start;
    padding-left: 10vw;
    display: block;
  }

  .contact-number {
    flex-direction: column;
    align-items: center;
  }

  .contact-number h2 {
    padding: 5vw;
  }
}

@media only screen and (min-width: 450px) and (max-width: 575px) {
  .map {
    right: -300px;
  }

  .links svg {
    margin: 0 16px;
  }

  .drop-pin {
    display: none;
  }

  .brooklin {
    top: -30px;
    right: 200px;
  }

  .fort, .manhattan {
    display: none !important;
  }

  .location-box {
    top: 300px;
    right: 90px;
  }

  .location-box img {
    max-width: unset;
  }

  .location-content {
    width: 346px;
    display: block;
    top: 312px;
    right: 20px;
  }

  .location-content-small {
    display: none;
  }

  .contact-number {
    flex-direction: column;
    align-items: center;
  }

  .contact-number h2 {
    padding: 5vw;
  }

  .drop-pin {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .map {
    right: -300px;
  }

  .links svg {
    margin: 0 16px;
  }

  .brooklin {
    top: -30px;
    right: 200px;
  }

  .fort, .manhattan {
    display: none !important;
  }

  .location-box {
    top: 300px;
    right: 90px;
  }

  .location-box img {
    max-width: unset;
  }

  .location-content {
    width: 346px;
    top: 312px;
    right: 20px;
  }

  .location-content-small {
    display: none;
  }

  .contact-number {
    flex-direction: column;
    align-items: center;
  }

  .contact-number h2 {
    padding: 5vw;
  }

  .drop-pin {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .map {
    right: -300px;
  }

  .links svg {
    margin: 0 16px;
  }

  .brooklin {
    top: -30px;
    right: 200px;
  }

  .fort {
    top: 320px;
    right: 530px;
  }

  .manhattan {
    display: none !important;
  }

  .location-box {
    top: 300px;
    right: 90px;
  }

  .location-box img {
    max-width: unset;
  }

  .location-content {
    width: 346px;
    top: 312px;
    right: 20px;
  }

  .location-content-small, .drop-pin {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .map-container {
    margin-top: 100px;
  }

  .map {
    right: -100px;
  }

  .links svg {
    margin: 0 16px;
  }

  .brooklin {
    top: -30px;
    right: 650px;
  }

  .fort {
    top: 320px;
    right: 740px;
  }

  .manhattan {
    display: none !important;
  }

  .location-box {
    top: 300px;
    right: 290px;
  }

  .location-box img {
    max-width: unset;
  }

  .location-content {
    width: 346px;
    top: 312px;
    right: 220px;
  }

  .location-content-small, .drop-pin {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .map-container {
    margin-top: 100px;
  }

  .map {
    right: -100px;
  }

  .links svg {
    margin: 0 16px;
  }

  .brooklin {
    top: -30px;
    right: 650px;
  }

  .fort {
    top: 320px;
    right: 770px;
  }

  .manhattan {
    display: none !important;
  }

  .location-box {
    top: 300px;
    right: 290px;
  }

  .location-box img {
    max-width: unset;
  }

  .location-content {
    width: 346px;
    top: 312px;
    right: 220px;
  }

  .location-content-small, .drop-pin {
    display: none;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1799px) {
  .map-container {
    margin-top: 100px;
  }

  .map {
    right: 0;
  }

  .links svg {
    margin: 0 16px;
  }

  .brooklin {
    top: -30px;
    right: 750px;
  }

  .fort {
    top: 320px;
    right: 870px;
  }

  .manhattan {
    top: -100px;
    right: 1420px;
  }

  .location-box {
    top: 300px;
    right: 390px;
  }

  .location-box img {
    max-width: unset;
  }

  .location-content {
    width: 346px;
    top: 312px;
    right: 320px;
  }

  .location-content-small {
    display: none;
  }

  #CONTACT {
    padding: 5vw 0 0;
  }

  #CONTACT h1 {
    margin-bottom: 10vw;
  }

  .drop-pin {
    display: none;
  }
}

@media only screen and (min-width: 1800px) {
  .map-container {
    margin-top: 200px;
  }
}

.cont .title-icon {
  margin-bottom: 50px;
}

.contact-number {
  margin: 40px 0 !important;
}

@media only screen and (max-width: 480px) {
  .map-container {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .map-container {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .map-container {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .map-container {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 1201px) {
  .location-box img {
    max-width: unset;
  }

  .links svg {
    margin: 0 20px;
  }

  .drop-pin {
    display: none;
  }
}

@media only screen and (min-width: 1846px) {
  .map {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .brooklin {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    top: -30px;
    left: 0;
    right: -125px;
  }

  .fort {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    top: 320px;
    left: 0;
    right: 125px;
  }

  .manhattan {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    top: -100px;
    left: 0;
    right: 1220px;
  }

  .location-box {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    top: 300px;
    left: 0;
    right: -720px;
  }

  .location-box img {
    max-width: unset;
  }

  .location-content {
    width: 346px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    top: 312px;
    left: 0;
    right: -860px;
  }

  .location-content-small {
    display: none;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1700px) {
  .manhattan {
    display: none;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 1845px) {
  .map {
    right: 0;
  }

  .brooklin {
    top: -30px;
    right: 750px;
  }

  .fort {
    top: 320px;
    right: 870px;
  }

  .manhattan {
    top: -100px;
    right: 1420px;
  }

  .location-box {
    top: 300px;
    right: 390px;
  }

  .location-box img {
    max-width: unset;
  }

  .location-content {
    width: 346px;
    top: 312px;
    right: 320px;
  }

  .location-content-small {
    display: none;
  }
}

/*# sourceMappingURL=index.ea592347.css.map */
